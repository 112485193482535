// Colors
$white: #ffffff;
$whiteSmoke: #f5f5f5;
$black: #000000;
$cyanBlue: #4484cebf;
$greyishBlack: #000000e6;
$grey: #000000b3;
$lightCyanBlue: #eaecefcf;
$brightGray: #eaecefcf;
$cultured: #f5f5f5;
$darkGunmetal: #212529;
$azure: #007bff;
$crayola: #0056b3;
$auroMetalSaurus: #6c757d;
$ghostWhite: #faf9f8;
$onyx: #343a40;
$gainsboro: #dae0e5;
$lightGrayishBlue: #dee2e6;
$vividBlue: #0d6efd;
$icewindDale: #e9ecef;
$mediumDarkCyanBlue: #495057;