@use './variables' as var;

* {
    box-sizing: border-box;
}

html {
    font-size: 14px;
    @media (min-width: 768px) {
        font-size: 16px;
    }
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: inherit;
    text-decoration: none;
}

.link {
    color: var.$azure;

    &:hover {
        color: var.$crayola;
        text-decoration: underline;
    }
}

p {
    line-height: 1.5;
}
