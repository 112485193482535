@use '../../scss/variables' as vars;

#home {
    min-height: 100vh;
    background-color: vars.$cyanBlue;
    color: vars.$white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;

    .text-center {
        .title {
            margin-top: 0 !important;
            font-weight: 300;
            font-size: 4.25rem;
            margin-bottom: 0.5rem;
            text-align: center !important;

            @media (min-width: 768px) {
                font-size: 6rem;
            }
            @media (min-width: 576px) {
                font-size: 5rem;
            }
        }

        .typist {
            font-size: 1.25rem;
            font-weight: 300;
            margin: 0 !important;
            text-align: center;
        }
    }

    .socials {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        padding: 3rem 0;

        .social-icon {
            .social-icon-link {
                font-size: 3rem;
                color: unset;
                text-decoration: none;

                &:hover > .icon {
                    transform: scale(1.1);
                }
            }
        }
    }

    .more-btn {
        font-size: 1.25rem;
        border: 1px solid vars.$white;
        border-radius: 0.35rem;
        background: transparent;
        padding: 0.55rem 1.1rem;
        color: inherit;
        text-decoration: none;
        transition: 0.25s all;

        &:hover {
            color: vars.$black;
            background: vars.$white;
        }
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
