.tab-panel {
    opacity: 0;
    display: none;

    &.active {
        display: block;
        opacity: 1;
    }

    .tab-panel-content {
        padding-top: 1.5rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        display: grid;

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 2.5rem;
        grid-row-gap: 2.5rem;
        justify-items: center;

        @media screen and (min-width: 576px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }

        .last-item {
            grid-area: 5 / 1 / 6 / 3;

            @media screen and (min-width: 576px) {
                grid-area: unset;
            }
        }

        .tab-panel-content-item {
            p {
                text-align: center;
                margin-top: 0.35rem;
                margin-bottom: 0;
            }
        }
    }
}
