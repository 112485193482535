@use '../../scss/variables' as var;

#projects {
    padding: 1rem 0;

    .projects-container {
        margin-left: auto;
        margin-right: auto;
        padding: 3rem 0;

        @media (min-width: 576px) {
            max-width: 540px;
        }

        @media (min-width: 768px) {
            max-width: 720px;
        }

        @media (min-width: 992px) {
            max-width: 960px;
        }

        @media (min-width: 1200px) {
            max-width: 1140px;
        }

        .projects-title {
            font-size: 3.5rem;
            margin: 0 0 3rem 0;
            font-weight: 300;
            text-align: center;
        }

        .projects-list {
            display: flex;
            flex-wrap: wrap;
        }

        .pagination {
            display: flex;
            flex-direction: row;
            gap: 0.75em;
            justify-content: center;

            .prev,
            .next,
            .paginationItem {
                display: inline-block;
                font-weight: 400;
                color: var.$auroMetalSaurus;
                text-align: center;
                vertical-align: middle;
                background-color: transparent;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                line-height: 1.5;
                border-radius: 0.25rem;
                transition: 0.15s ease-in-out box-shadow;
                cursor: pointer;
            }

            .paginationItem {
                border: 1px solid var.$auroMetalSaurus;

                &:active {
                    box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
                }

                &.active {
                    color: var.$white;
                    background-color: var.$auroMetalSaurus;
                    border-color: var.$auroMetalSaurus;
                }
            }

            .prev,
            .next {
                border: none;

                &:active {
                    box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
                }
            }
        }
    }
}
